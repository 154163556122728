.background {
  height: 100vh;
  background-color: #ffffff;
  overflow: auto;
  overflow-x: hidden;
}
.navbar {
  background-color: #6148a1;
  color: #fff !important;
}
.nav-link {
  height: 100%;
  padding-bottom: 15px;
  border-bottom: 3px solid transparent;
  font-family: "Roboto";
  font-style: normal;
  font-size: 14px;
  font-weight: 200;
  line-height: 16px;
  letter-spacing: 0em;
  color: #fff;
  border-radius: 4px;
}

.nav-navlinks {
  margin-bottom: -8px;
}
.userName {
  color: white;
  font-size: 16px;
  font-weight: 200;
}
.image-user {
  width: 40px;
  height: 40px;
  object-fit: fill;
  /* border: 1px solid white; */
}
.image-user-drawer {
  width: 80px;
  height: 80px;
}

.navicons-div {
  margin-top: 2px;
  width: 22px;
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
}

.notification-sign {
  position: relative;
  top: -6px;
  left: 1px;
  margin: -10px;
  margin-right: -40px;
}
.togglebtn::after {
  display: none;
}

.navbar-nav .dropdown-menu {
  /* color: #901fd1; */

  position: absolute;
  right: 1rem;
  min-width: 7.3rem;
  max-width: 10rem;
  font-size: 14px;
  /* text-align: left; */
  background-color: #ececec;
  border: none;
}
.dropdown-menu-item {
  padding: 3px 1.5rem;
}
.dropdown-menu-item:hover {
  background-color: #ffffff;
}
.dropdown-menu-item {
  color: #000000;
}
.dropdown-menu-item.active {
  color: #000000;
  background-color: #ececec;
}


/* search Bar */
.search-box {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 1px;
  /*  background-color: orange;*/
}
.input-search {
  height: 35px;
  width: 0px;
  border-style: none;
  padding-left: 10px;
  font-size: 13px;
  outline: none;
  transition: all 0.5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color: #fff;
}
.input-search::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 100;
}
.btn-search {
  border-style: none;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 0px;
  color: #ffffff;
  background-color: transparent;
  pointer-events: painted;
}
.btn-search:focus ~ .input-search {
  width: 200px;
  background-color: transparent;
  /* border-bottom:1px solid rgba(255,255,255,.5); */
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.input-search:focus {
  width: 200px;
  border-radius: 0px;
  background-color: transparent;
  font-size: 15px;
  /* border-bottom:1px solid rgba(255,255,255,.5); */
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.message,
.notify {
  cursor: pointer;
}

/* Map home */
.map-wrapc {
  z-index: 1;
  padding-left: 17px;
  position: relative;
  width: 100%;
  height: calc(100vh - 120px);
  background-color: transparent;
}
.mapboxgl-ctrl-attrib-inner {
  display: none;
}
.maps {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.mapboxgl-popup-content {
  display: flex;
  /* padding: 5px 3px; */
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid #fea115;
  border-radius: 25px;
}
.HomeButtons {
  position: absolute;
  top: 4%;
  left: 0.5%;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.some-class {
  background-color: #fea115;
}
.popup {
  width: fit-content;
  height: fit-content;
  position: relative;  
}

.mapboxgl-popup-content{
  display: flex;
  align-items: center;
}

/* .mapboxgl-popup-content aside {
  width: 20px;
  height: 20px;
  background-color:rgba(255, 193, 71, 0.4);;
  position: absolute;
  bottom: -12px;
  transform: rotate(45deg);
  z-index: 1;
  border-right: 2px solid #FEA115;
  border-bottom: 2px solid #FEA115;
  border-radius: 5px;
} */

.mapboxgl-popup-content img {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  align-items: center;
  margin-right: 6px;
}

.mapboxgl-popup-content h6 {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  margin-top: -2px;
}
.mapboxgl-popup-content div {
line-height: 4px;
}
.mapboxgl-popup-content span {
  font-weight: lighter;
  
} .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip{
  /* position: absolute;
  left: 20px;
  bottom: -10px; */
  /* margin-right: 60%; */
  border-top-color: rgba(255, 193, 71, 0.4) !important;
  /* border-top:"1px solid red" !important; */
  /* border: 5px solid #FEA115; */
  /* background-color: #FEA115; */
}
.apple-popup .mapboxgl-popup-content {
  /* background-size: fit-content;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none; */
  background-color: rgba(255, 193, 71, 0.4);
  border: 2px solid #FEA115;
  border-radius: 8px;
}
.apple-popup-business .mapboxgl-popup-content {
  background-color: rgba(255, 193, 71, 0.4);
}
.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border-bottom-color: #FEA115;
    }
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    border-top-color: #FEA115;
    }
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: #FEA115;
    }
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: #FEA115;
}

/* user Panel */
.main {
  z-index: 100;
  font-family: "Noto Sans JP", sans-serif;
  position: relative;
  height: calc(100vh - 151px);
  background-color: #f7f8fa;
  margin-right: 15px;
  border: none;
  border-radius: 10px;
  /* overflow: auto; */
}
.main-head {
  background-color: #0079cb;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 6px;
}
/* .userspanl:hover .right {
	 background-color: #cddc9d;
	color: grey; 
} */
.scroll-down-button {
  background-color:transparent;
  color: #fff;
  border: none;
  border-radius: 3px; 
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.Listmain {
  height: calc(100vh - 215px);
}
.ListAll {
  overflow-y: auto;
  overflow-x: hidden;
}
.cartBusiness {
  width: 24px;
  height: 22px;
  margin: 5px 3px 0px 5px;
}
.TwoUsers {
  width: 27px;
  height: 23px;
  margin: 5px 3px 0px 5px;
}
.Twoblogs {
  width: 25px;
  height: 23px;
  margin: 5px 3px 0px 5px;
}
.Twoevents {
  width: 17px;
  height: 25px;
  margin: 5px 5px 0px 8px;
}
.heading {
  color: white;
  font-weight: 500;
  margin-top: 7px;
  padding-left: 4px;
  font-size: 16px;
}

@media only screen and (max-width: 576px) {
  .ListAll-sm {
    position: absolute;
    width: 100%;
    left: 12px;
    bottom: 65px;
    height: calc(100vh - 490px);
    z-index: 100;
    border-radius: 20px;
    background-color: #fff;
    /* background: linear-gradient(to bottom, rgb(255, 255, 255) 0%,rgba(255, 255, 255, 0) 100%); */
    overflow:scroll;
  }

  .main {
    height: 52px;
  }

  .Listmain {
    height: 0px;
  }
}

.userspanl {
  color: #000000;
  position: relative;
  background-color: #ffffff;
  padding: 10px 1px 5px 10px;
  margin: 10px 20px 0px 20px;
  border: 0.1px solid #000000;
  border-radius: 10px;
}
.userspanl:hover {
  background-color: #0079cb;
  color: #ffffff;
}
.userspanlBussiness {
  color: #000000;
  position: relative;
  background-color: #ffffff;
  padding: 10px 1px 5px 10px;
  margin: 10px 20px 0px 20px;
  border: 0.1px solid #000000;
  border-radius: 10px;
}
.userspanlBussiness:hover {
  background-color: #40b207;
  color: #ffffff;
}
.userspanlEvents {
  color: #000000;
  position: relative;
  background-color: #ffffff;
  padding: 10px 1px 5px 10px;
  margin: 10px 20px 0px 20px;
  border: 0.1px solid #000000;
  border-radius: 10px;
}
.userspanlEvents:hover {
  background-color: #984399;
  color: #ffffff;
}
.userspanlBlogs {
  color: #000000;
  position: relative;
  background-color: #ffffff;
  padding: 10px 1px 5px 10px;
  margin: 10px 20px 0px 20px;
  border: 0.1px solid #000000;
  border-radius: 10px;
}
.userspanlBlogs:hover {
  background-color: #e40090;
  color: #ffffff;
}
.contact-online {
  position: absolute;
  margin-top: 30px;
  margin-left: 31px;
}
.contact-idle {
  position: absolute;
  margin-top: 30px;
  margin-left: 31px;
}
.image {
  width: 45px;
  height: 45px;
  margin-left: -4px;
}
.namees {
  font-size: 12px;
  font-weight: bold;
  margin: 4px 0px 2px -8px;
  word-wrap: break-word;
  /* white-space: nowrap; */
}
.info {
  font-size: 12px;
}
.right {
  background-color: #0079cb;
  position: absolute;
  padding: 21.5px 10px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  color: #fff;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.rightEvents {
  background-color: #984399;
  position: absolute;
  padding: 21.5px 10px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  color: #fff;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.rightBlog {
  background-color: #e40090;
  position: absolute;
  padding: 21.5px 10px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  color: #fff;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.rightBusssiness {
  background-color: #40b207;
  position: absolute;
  padding: 21.5px 10px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  color: #fff;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.addEventBtn {
  font-family: "Roboto";
  width: 85%;
  height: 48px;
  margin: 5px 0px 5px 7%;
  background-color: #984399;
  color: white;
  border: none;
  border-radius: 8px;
  bottom: 15px;
}
.BsPlusLg-event {
  margin-bottom: 3px;
  margin-right: 10px;
}
.addBlogBtn {
  font-family: "Roboto";
  width: 85%;
  height: 48px;
  margin: 5px 0px 5px 7%;
  background-color: #e40090;
  color: white;
  border: none;
  border-radius: 8px;
  bottom: 15px;
}
.first {
  margin-top: 6px;
  margin-left: 5px;
}

/* footer nav login buttoon */
.loginbtn {
  width: 100px;
  height: 45px;
   /* position: absolute; 
   right: 15px;  */
  border: 2px solid #ffc107;
  background-color: #ffc107;
  border-radius: 8px;
}
.loginbtn:hover {
  border: 2px solid #ffc107;
  z-index: 1000;
  box-shadow: 12px 3px 8px rgba(21, 48, 89, 0.3);
}
.marker {
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
}
.mapbox-map {
  width: 100%;
  height: calc(100vh-77px);
  border-radius: "10px";
  margin-left: 14px;
}
.mapboxgl-ctrl-geocoder {
  background-image: url(../../../public/img/geoIcon.png);
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 6px;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl .mapboxgl-ctrl-geocoder--icon-search {
  display: none;
}
.map-Main .mapboxgl-ctrl-top-right{
  position: absolute;
  top: 0px ;
  bottom: 0px ;

}
@media only screen and (max-width: 430px) {
  .map-Main .mapboxgl-ctrl-top-right{
    width: auto;
    margin: auto;
    position: relative;
    left: 25px ;
    top: calc(100vh - 220px);
    margin-right: 60px;
  }
  .HomeButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -25px;
    left: 15%;
    /* position: absolute; */
    /* top: 1%; */
    z-index: 1;
  }
  .map-Main .mapboxgl-ctrl-geocoder .suggestions{
    top: -340px;
  }
  
}

.dropdown-toggle::after {
  display: none;
}

.notibox {
  min-width: 366px;
  border: 1px solid #6148a1;
}

.notif-box-Main {
  position: absolute;
  right: -83px;
  top: 30px;
  z-index: 1010;
}

.msg-box-Main {
  position: absolute;
  right: -83px;
  top: 30px;
  z-index: 1000;
}

.nb-pointer {
  width: 25px;
  height: 26px;
  background-color: #6148a1;
  position: relative;
  right: -260px;
  top: 18px;
  transform: rotate(45deg);
}

.notibox-Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #6148a1;
}
.notifications {
  /* background-color: #ececec; */
  height: 220px; 
  overflow: auto;
}
.Searchbox-Main {
  position: absolute;
  right: -90px;
  top: 18px;
  z-index: 1000;
}

.Searchbox {
  min-width: 360px;
  border: 0px;
}
/* @media only screen and (max-width: 992px) {
  .notif-box-Main {
    position: absolute;
    right: 170px;
    top: 45px;
    z-index: 1000;
  }
  
  .msg-box-Main {
    position: absolute;
    right: -83px;
    top: 30px;
    z-index: 1000;
  }

 .Searchbox-Main {
   position: absolute;
   right: 250px;
   top: 30px;
 }
}

@media only screen and (max-width: 764px) {

  .notif-box-Main {
    position: absolute;
    right: 155px;
    top: 45px;
    z-index: 1000;
  }
  
  .msg-box-Main {
    position: absolute;
    right: -82px;
    top: 30px;
    z-index: 1000;
  }
  
 .Searchbox-Main {
   position: absolute;
   right: 160px;
   top: 45px;
 }
}

@media only screen and (max-width: 576px) {
  .notif-box-Main {
    position: absolute;
    right: -80px;
    top: 45px;
    z-index: 1000;
  }
  
  .msg-box-Main {
    position: absolute;
    right: -80px;
    top: 30px;
    z-index: 1000;
  }


 .Searchbox-Main {
   position: absolute;
   right: 70px;
   top: 30px;
 }
} */

@media only screen and (max-width: 460px) {
  .notif-box-Main {
    position: absolute;
    right: -60px;
    top: 30px;
    z-index: 1000;
  }
  
  .msg-box-Main {
    position: absolute;
    right: -60px;
    top: 30px;
    z-index: 1000;
  }

  .notibox {
    min-width: 300px;
    border: 1px solid #6148a1;
  }

  .nb-pointer {
    /* visibility: hidden; */
    position: relative;
    right: -215px;
  }

  .Searchbox-Main{
    position: absolute;
    top: 20px;
    right: -90px;
  }
  
  .Searchbox-Main .nb-pointer{
    position: relative;
    right: -205px;
  }

  .Searchbox{
    min-width: 300px;
  }
  .btn-search:focus ~ .input-search {
    width: 100px;
    background-color: transparent;
    /* border-bottom:1px solid rgba(255,255,255,.5); */
    transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
  }
  .input-search:focus {
    width: 100px;
    border-radius: 0px;
    background-color: transparent;
    font-size: 15px;
    /* border-bottom:1px solid rgba(255,255,255,.5); */
    transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
  }

}
@media only screen and (max-width: 370px) {

  .nb-pointer {
    /* visibility: hidden; */
    position: relative;
    right: -215px;
  }

  .Searchbox-Main{
    position: absolute;
    top: 20px;
    right: -150px;
  }

}
@media screen and (min-width: 992px ) and (max-width: 1200px) {
  .user-name span{
   width: 80px;
   white-space: pre-wrap;
  }
  .image {
    width: 35px;
    height: 35px;
    margin-left: -4px;
  }
  .contact-online {
    position: absolute;
    margin-top: 14px;
    margin-left: 26px;
  }
  .contact-idle {
    position: absolute;
    margin-top: 14px;
    margin-left: 26px;
  }
}
/* Chat box */
.chatbox-Main {
  z-index: 5;
  max-width: 500px;
}

.chatbox-Header {
  background-color: #6148a1;
}

.chatbox {
  width: 300px;
  height: 400px;
  border: 1px solid #6148a1;
}
.chatphoto{
width: 20%;
left: 10px;
}

.ChatClose {
  float: inline-end;
  margin-top: -10px;
}

.chats .recieved {
  display: flex;
  justify-content: flex-start;
}

.chats .sent .imagesss {
  margin-left: -22px;
  margin-top: 0px;
  border: 1px solid #6148a1;
}

.chats .recieved img {
  margin-right: -22px;
  margin-top: 0px;
  border: 2px solid #6148a1;
}

.chats .sent {
  display: flex;
  justify-content: flex-end;
}

.chats .msgBubble {
  max-width: 260px;
  min-width: 100px;
}
.chat-Main {
  /* width: 400px; */
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 1;
}
.chatImage{
  min-width: 100%;
  /* margin: auto; */
  height: 120px;
  object-fit: fill;
  border: 1px solid grey;
  border-radius: 4px;
}
#text .react-emoji{
  width: 100%;
  height: 42px;
  margin-left: 0px;
}
#text .react-input-emoji--button {
  z-index: 1;
  right: 2px;
  position: absolute;
}
#text .react-input-emoji--input {
  color: #000000;
  font-style: normal;
  font-size: 14px;
  background-color: #ececec;
  outline: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-left: 35px;
}
#text .react-input-emoji--placeholder {
  padding-left: 25px;
}
#text .react-input-emoji--wrapper{
  width:244px;
  height: 40px;
}
#text .react-emoji-picker{
  position: absolute;
  top: 80px;
}
#text .react-emoji-picker--wrapper{
  right: -90px;
}
#text .emoji-mart{
width: 290px !important;
padding-top: 10px;
}
#text .emoji-mart-bar{
display: none;
}
#text .emoji-mart-search{
  display: none;
}

/* .react-emoji-picker::before{
  content: "x";
  color:black;
  font-size: larger;
  font-weight: bolder;
  padding-right: 8px;
  display: flex;
  justify-content: flex-end;
  margin-top: 20;
/* } 

/* Loader Css */
.balls {
  width: 4em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.balls div {
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: #6148a1;
 
}

.balls div:nth-of-type(1) {
  transform: translateX(-100%);
  animation: left-swing 0.5s ease-in alternate infinite;
}

.balls div:nth-of-type(3) {
  transform: translateX(-95%);
  animation: right-swing 0.5s ease-out alternate infinite;
}

@keyframes left-swing {
  50%,
  100% {
    transform: translateX(95%);
  }
}

@keyframes right-swing {
  50% {
    transform: translateX(-95%);
  }
  100% {
    transform: translateX(100%);
  }
}

