@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.navbar {
  background-color: #6148a1;
}

.logodiv {
  border-right: 1px solid white;
  height: 32px;
}

.image-logo {
  height: 28px;
  padding-right: 40px;
}

.navlink {
  color: white;
  font-size: 14px;
  border-bottom: 3px solid transparent;
}

.navlink.active {
  font-weight: 900;
  border-bottom: 3px solid #fea115;
  border-radius: 3px;
  text-decoration: none;
  color: white;
}

.icons {
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-right: 1px solid white;
  margin-right: 34px;
  height: 32px;
}

.icons * {
  margin-right: 15px;
}

.user-profile {
  color: white;
  font-weight: 600;
}

.user-profile:hover {
  color: white;
}

.img-profile {
  border-radius: 50%;
  background-color: white;
  border: 1px solid #7f61c9;
  margin: 16px;
  width: 32px;
  height: 32px;
}

.img-cover {
  width: auto;
  overflow: hidden;
}

.cover {
  min-width: 100%;
  height: 48vh;
  object-fit:cover;
  
}
.userImage {
  margin-left: 80px;
}
.img-admin {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid white;
  /* object-fit:cover; */
  image-rendering: auto;

}

.profile {
  margin-top: -120px;
}

.add-chat {
  margin-top: -20px;
}

.add-btn {
  background-color: #6148a1;
  color: white;
  font-size: 14px;
}
.add-btn-b {
  width: 253px;
  background-color: #6148a1;
  color: white;
  font-size: 14px;
}

.chat-btn {
  background-color: #fea115;
  color: white;
  font-size: 14px;
}

.add-btn:hover {
  background-color: #4c338c;
  color: white;
}

.chat-btn:hover {
  background-color: #df8b0c;
  color: white;
}

.Post-btn-Aside {
  width: 64px;
  height: 40px;
  margin-top: 6px;
  background-color: #6148a1;
  color: white;
  border: none;
  border-radius: 5px;
  border-bottom-left-radius: 1px;
  border-top-left-radius: 1px;
  border-color: #6148a1;
}

.search-btn:hover {
  background-color: #4c338c;
  border-color: #4c338c;
  color: white;
}

.search-btn:focus {
  border-color: #ececec;
}

.send-btn {
  background-color: #6148a1;
  color: #fff;
  padding: 4px;
  border-radius: 0px 4px 4px 0px;
  border-color: #6148a1;
}

.send-btn:hover {
  background-color: #4c338c;
  color: white;
  border-color: #4c338c;
}

.send-btn:focus {
  border-color: #ececec;
  box-shadow: 0 1px 1px #ececec inset, 0 0 8px #ececec;
}

.allpost_div {
  height: 650px;
  overflow-y: auto;
}
.allpost-user {
  height: 700px;
  overflow-y: auto;
}
.userNames{
  color: white;
}
/* userName Media */
/* @media only screen and (min-width: 768px) {
 .userNames{
  width:100px
 }
} */

/* width */
/* ::-webkit-scrollbar {
    width: 4px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
    background: #C7A8F6;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
    background: #6148A1;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #4c338c;
} */
.statusImage{
  width: 100%;
  border: 1px solid grey;
  border-radius: 4px;
}
.send-form {
  color: #ececec;
  font-style: italic;
  font-size: 14px;
  background-color: #ececec;
  padding: 10px;
  margin-left: -31px;
  outline: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.send-form:focus {
  border-color: #ececec;
  box-shadow: 0 1px 1px #ececec inset, 0 0 8px #ececec;
}
#status .react-emoji {
  width: 83%;
  margin-left: 5px;
}
#status .react-input-emoji--container{
  margin: 5px -3px;
}
#status .react-input-emoji--input {
  color: #000000;
  font-style: normal;
  font-size: 14px;
  background-color: #ececec;
  outline: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-left: 35px;
}
#status .react-input-emoji--placeholder {
  padding-left: 25px;
}

#status .react-input-emoji--input:focus {
  border-color: #ececec;
  box-shadow: 0 1px 1px #ececec inset, 0 0 8px #ececec;
}
#status .react-input-emoji--wrapper{
  width:auto;
  height: 40px;
}
#status .react-emoji-picker{
  position: absolute;
  top: 80px;
}
#status .react-emoji-picker--wrapper{
  right: -90px;
}
#status .emoji-mart{
width: 290px !important;
padding-top: 10px;
}
#status .emoji-mart-bar{
display: none;
}
#status .emoji-mart-search{
  display: none;
}
#status .react-input-emoji--button {
  z-index: 1;
  right: 2px;
  position: absolute;
}
#comment .react-emoji{
  width: 90%;
}
#comment .react-input-emoji--button {
  z-index: 1;
  right: 2px;
  position: absolute;
}
#comment .react-input-emoji--container{
  margin: 5px 0px;
}
#comment .react-input-emoji--input {
  padding: 10px 18px;
  color: #000000;
  font-style: normal;
  font-size: 14px;
  background-color: #ececec;
  outline: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
#comment .react-input-emoji--wrapper{
  width:auto;
  height: 40px;
}
#comment .react-emoji-picker{
  position: absolute;
  top: 80px;
}
#comment .react-emoji-picker--wrapper{
  right: -90px;
}
#comment .emoji-mart{
width: 290px !important;
padding-top: 10px;
}
#comment .emoji-mart-bar{
display: none;
}
#comment .emoji-mart-search{
  display: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  top: 2px;
  left: 2px;
  border-radius: 30px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #18d989;
}

input:checked + .slider:before {
  border-radius: 50%;
  background-color: #fff;
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.privacy-label {
  color: black;
  font-size: 13px;
  font-weight: 500;
}


.img-gal{
  width: 105px;
  height: 86px;
  object-fit:fill;
  margin-right: 14px;
  cursor: pointer;
}
.styles-module_wrapper__1I_qj {
  z-index: 100 !important;
}
.Gal-Main{
  overflow-x: auto;
  overflow-y: hidden;
}
.Gal-Main div img{
  min-width: 105px;
  min-height: 86px;
  margin-right: 14px;
}
.Gal-Main::-webkit-scrollbar {
  height: 2px;
}
.gallary-upload2{
  width:340px;
  height:340px;
  border-radius:10px;
  object-fit: cover;
  align-items: center;
}
.gallary-upload{
  width:140px;
  height:140px;
  border-radius:10px;
  object-fit: cover;
  align-items: center;
}
.friends_row {
  margin-top: 0px;
  height: 80px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
}
.friends_row::-webkit-scrollbar {
  height: 2px;
}

 .clip-icon {
  z-index: 10;
  position: absolute;
  top: 12px;
  left: 8px;
  background-color: #6148a1;
  /* border-top-left-radius: 6px; */
  /* border-bottom-left-radius: 6px; */
  color: white;
  font-size: 28px;
} 

.user-bprofile {
  color: black;
}

.jdp {
  background-color: #f8f8f8;
}
.statusMain{
  background-color: #f8f8f8;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-height: 85vh;
}
@media only screen and (max-width: 768px) {
 .statusMain{
  width:90%
 }
}


.date {
  font-size: 9px;
  font-weight: 300;
}

.desc {
  font-size: 12px;
}

.pro-img {
  margin-top: -30px;
}
.imageChat{
  max-width: 150px !important;
  min-width: 150px !important;
  height:150px !important;
  object-fit: cover;
}

.desc-icons {
  font-weight: 400;
  color: black;
}

.icons-chat {
  color: #6599af;
  font-size: 12px;
}

.icons-thumb {
  color: #6599af;
  font-size: 16px;
}

.icons-heart {
  cursor: pointer;
}

.smile-icons {
  color: #6148a1;
  font-size: 35px;
  position: relative;
}

.friends {
  font-size: 12px;
  font-weight: 700;
}

.border1 {
  position: relative;
  top: 4px;
  border-bottom: 5px solid #8a438b;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  width: 100px;
  margin-left: 16px;
  /* margin-right: 40rem; */
}

.jacob {
  font-size: 13px;
  font-weight: 700;
}

.map-wrappp {
  position: relative;
  width: 100%;
  height: calc(100vh - 70px);
  height: 328px;
}

.mappp {
  position: absolute;
  margin-top: 20px;
  margin-left: 20px;
  width: 96%;
  height: 267px;
  border-radius: 10px;
}

@media ((min-width: 546px) and (max-width: 684px)) {
  .mappp{
    width: 94%;
  }
}

@media ((min-width: 408px) and (max-width: 546px)) {
  .mappp{
    width: 92%;
  }
}

@media only screen and (max-width: 408px) {
  .mappp{
    width: 90%;
  }
}

.california {
  z-index: 1;
  position: absolute;
  bottom: 5px;
  color: black;
  margin-bottom: 0px;
  left: 15px;
  font-size: 14px;
  font-weight: 600;
}

.foot-nav {
  width: 100%;
  background-color: #6148a1;
  color: white;
  align-items: center;
}
.foot-nav .nav-link {
  color: #fff;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 300;
  padding: 10px;
  padding-top: 14px;
  /* text-transform : capitalize; */
}
.font_text {
  font-size: 12px;
  align-items: justify;
  color: #fff;
}
/* Event Screen */
.main-event {
  height: 100vh;
  background-color: #fff;
}
.not-goingbtn {
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  -moz-box-shadow: 0 1px 5px #60626d;
  -webkit-box-shadow: 0 1px 5px #60626d;
  box-shadow: 0 1px 5px #60626d;
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  cursor: pointer;
  padding: 10px 16px;
  background-color:#8379b7;
}
.not-goingbtn:hover {
  background-color: rgba(131, 121, 183, 1);
}
.not-goingbtn:focus {
  outline: 0;
}
.goingbtn {
  height: 42px;
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  -moz-box-shadow: 0 1px 5px #60626d;
  -webkit-box-shadow: 0 1px 5px #60626d;
  box-shadow: 0 1px 5px #60626d;
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  cursor: pointer;
  padding: 10px 36px;
  background: -webkit-linear-gradient(left, #fa834b 0%, #fab04b 100%);
}
.goingbtn:hover {
  background: #fa834b;
}
.img-cover-event {
  width: auto;
}
.cover-event {
  width: 100%;
  border-radius: 10px;
  background-size: 100% 100%;
}
.event-des-head {
  color: #474d59;
  font-size: 1.5em;
  margin-top: 0;
}
.event-des-text {
  color: #5e6573;
  font-size: 13px;
  display: block;
  line-height: 1.8em;
}
.map-wrap-event-screen {
  position: relative;
  left: 15%;
  width: 70%;
  height: 287px;
}
.map-event-screen {
  position: absolute;
  margin-top: 9px;
  margin-left: 1%;
  width: 98%;
  height: 270px;
  border-radius: 10px;
}
/* Popup share profile */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}

.popup-imageviewer img{
  height: 100%;
}

.shareButtons-div {
  max-width: 465px;
  overflow-x: scroll;
}

@media only screen and (max-width: 546px) {
  .popup-imageviewer img{
    height: fit-content;
  }

  .shareButtons-div {
    width: 100%;
  }
}

.box {
  position: relative;
  min-width: 320px;
  max-width: 620px;
  height: fit-content;
  margin: 0 auto;
  /* max-height: 70vh; */
  /* margin-top: calc(100vh - 60vh - 20px); */
  background: #fff;
  border-radius: 6px;
  /* padding: 40px 20px; */
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: absolute;
  right: 1px;
  top: 1px;
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

/* loader */
.loading {
  border: 4px solid rgba(0, 0, 0, .1);
  border-left-color: transparent;
  border-radius: 50%;
}

.loading {
  border: 4px solid rgba(0, 0, 0, .1);
  border-left-color: transparent;
  width: 160px;
  height: 160px;
}

.loading {
  border: 2px solid #6148a1;
  border-left-color: transparent;
  width: 150px;
  height: 150px;
  animation: spin89345 0.5s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/* loader2 */
.loading2 {
  border: 4px solid rgba(0, 0, 0, .1);
  border-left-color: transparent;
  border-radius: 50%;
}

.loading2 {
  border: 4px solid rgba(0, 0, 0, .1);
  border-left-color: transparent;
  width: 60px;
  height: 60px;
}

.loading2 {
  border: 2px solid #6148a1;
  border-left-color: transparent;
  width: 50px;
  height: 50px;
  animation: spin89345 0.5s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/* loader3 */
.loading3 {
  border: 4px solid rgba(255, 255, 255);
  border-left-color: transparent;
  border-radius: 50%;
}

.loading3 {
  border: 4px solid rgba(255, 255, 255);
  border-left-color: transparent;
  width: 20px;
  height: 20px;
}

.loading3 {
  border: 2px solid #fff;
  border-left-color: transparent;
  width: 20px;
  height: 20px;
  animation: spin89345 0.5s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.text-heading-size{
  font-size: 20px;
}
.text-size, .adress-size {
  font-size: 17px;
}

.icon-size {
  font-size: 19px;
}

.about-b-divs{
  margin-top: 28px;
}

@media ((min-width: 10px) and (max-width :312px)) {
  .p-text-size {
    font-size: 5px;
  }

  .F-text-size {
    font-size: 4px;
  }

  .text-size, .adress-size {
    font-size: 5px;
  }
  
  .icon-size {
    font-size: 9px;
  }

  .text-heading-size {
    font-size: 10px;
  }

  .about-b-divs{
    margin-top: 10px;
  }
}

@media ((min-width: 312px) and (max-width :354px)) {
  .p-text-size {
    font-size: 5px;
  }

  .F-text-size {
    font-size: 4px;
  }

  .text-size, .adress-size {
    font-size: 6px;
  }
  
  .icon-size {
    font-size: 10px;
  }

  .text-heading-size {
    font-size: 10px;
  }

  .adress-size {
    font-size: 10px
  }
  
  .about-b-divs{
    margin-top: 12px;
  }
}

@media ((min-width: 354px) and (max-width :396px)) {
  .p-text-size {
    font-size: 5px;
  }

  .F-text-size {
    font-size: 5px;
  }

  .text-size, .adress-size {
    font-size: 7px;
  }
  
  .icon-size {
    font-size: 11px;
  }

  .text-heading-size {
    font-size: 10px;
  }

  .about-b-divs{
    margin-top: 14px;
  }
}

@media ((min-width: 396px) and (max-width :438px)) {
  .p-text-size {
    font-size: 7px;
  }

  .F-text-size {
    font-size: 6px;
  }

  .text-size, .adress-size {
    font-size: 8px;
  }
  
  .icon-size {
    font-size: 12px;
  }

  .text-heading-size {
    font-size: 10px;
  }
  
  .about-b-divs{
    margin-top: 15px;
  }
}

@media ((min-width: 438px) and (max-width :480px)) {
  .p-text-size {
    font-size: 7px;
  }

  .F-text-size {
    font-size: 7px;
  }

  .text-size, .adress-size {
    font-size: 9px;
  }
  
  .icon-size {
    font-size: 13px;
  }

  .text-heading-size {
    font-size: 10px;
  }

  .about-b-divs{
    margin-top: 16px;
  }
}


@media ((min-width: 480px) and (max-width :564px)) {
  .p-text-size {
    font-size: 8px;
  }

  .F-text-size {
    font-size: 8px;
  }

  .text-size, .adress-size {
    font-size: 10px;
  }
  
  .icon-size {
    font-size: 14px;
  }

  .text-heading-size {
    font-size: 14px;
  }
  
  .about-b-divs{
    margin-top: 19px;
  }
}

@media ((min-width: 564px) and (max-width :626px)) {
  .p-text-size {
    font-size: 12px;
  }

  .F-text-size {
    font-size: 10px;
  }

  .text-size, .adress-size {
    font-size: 12px;
  }
  
  .icon-size {
    font-size: 15px;
  }

  .text-heading-size {
    font-size: 15px;
  }
  
  .about-b-divs{
    margin-top: 20px;
  }
}

@media ((min-width: 626px) and (max-width :788px)) {
  .p-text-size {
    font-size: 12px;
  }

  .F-text-size {
    font-size: 12px;
  }

  .text-size, .adress-size {
    font-size: 13px;
  }
  
  .icon-size {
    font-size: 16px;
  }
  
  .about-b-divs{
    margin-top: 22px;
  }
}

@media ((min-width: 788px) and (max-width :992px)) {
  .p-text-size {
    font-size: 13px;
  }

  .F-text-size {
    font-size: 13px;
  }

  .text-size, .adress-size {
    font-size: 15px;
  }
  
  .icon-size {
    font-size: 17px;
  }
  
  .about-b-divs{
    margin-top: 24px;
  }
}

@media ((min-width: 992px) and (max-width : 1078px)) {
  .p-text-size {
    font-size: 12px;
  }

  .F-text-size {
    font-size: 12px;
  }

  .text-size, .adress-size {
    font-size: 14px;
  }
  
  .icon-size {
    font-size: 18px;
  }
  
  .about-b-divs{
    margin-top: 26px;
  }
}

@media ((min-width: 1078px) and (max-width:1870px)) {
  .p-text-size {
    font-size: 14px;
  }

  .F-text-size {
    font-size: 14px;
  }

  .text-size, .adress-size {
    font-size: 15px;
  }
  
  .icon-size {
    font-size: 18px;
  }
  
  .about-b-divs{
    margin-top: 28px;
  }
}