.footer {
    width: 100%;
    background-color: #6148a1;
    padding: 20px 0;
  }
  
  .footer-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .footer-links {
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .footer-links a {
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
  }
  .footer-links a:hover {
    color: #D1D1D1;
  }
  
  @media (max-width: 768px) {
    .footer-container {
        justify-content: center;
        align-items: center;
      }
    .footer-links {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }